import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  svgStyle?: Record<string, unknown>;
};
const ArrowIcon: React.FC<Props> = (props) => {
  const size = props.size ? String(props.size) : "24";

  return (
    <span style={props.style}>
      <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        style={{
          transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
          ...props.svgStyle,
        }}
      >
        <line
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5"
          y1="12"
          x2="19"
          y2="12"
        />
        <polyline
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="12 5 19 12 12 19"
        />
      </svg>
    </span>
  );
};
ArrowIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default ArrowIcon;
