import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  svgStyle?: Record<string, unknown>;
};
const ChatIcon: React.FC<Props> = (props) => {
  const size = props.size ? String(props.size) : "24";

  return (
    <span style={props.style}>
      <svg
        width={size}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
          ...props.svgStyle,
        }}
      >
        <path
          d="M19.2139 13.1163C19.2139 13.6467 19.0032 14.1554 18.6281 14.5305C18.2531 14.9056 17.7444 15.1163 17.2139 15.1163H5.21393L1.21393 19.1163V3.11627C1.21393 2.58584 1.42464 2.07713 1.79971 1.70206C2.17479 1.32699 2.6835 1.11627 3.21393 1.11627H17.2139C17.7444 1.11627 18.2531 1.32699 18.6281 1.70206C19.0032 2.07713 19.2139 2.58584 19.2139 3.11627V13.1163Z"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
ChatIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default ChatIcon;
