import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const PdfIcon: React.FC<Props> = ({ size, style, color }) => {
  const width = String(size);
  const height = String((23 / 20) * size);

  return (
    <span style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0076 2.1639L17.7128 1.45488L17.0076 2.1639L17.8208 2.97264C18.5756 3.7234 19 4.7441 19 5.80872V18C19 20.2091 17.2091 22 15 22H5C2.79086 22 1 20.2091 1 18V4.99999C1 2.79086 2.79086 0.999997 5 0.999994L14.1869 0.999983C15.244 0.999982 16.2581 1.41844 17.0076 2.1639Z"
          stroke={color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinejoin="round"
        />
        <path
          d="M12.5493 13.1217C12.0864 12.7878 11.6465 12.4445 11.3535 12.176C10.9727 11.8269 10.6333 11.4885 10.3384 11.1662C10.7984 9.86327 11 9.19145 11 8.83335C11 7.312 10.4004 7 9.50003 7C8.81594 7 8.00003 7.32583 8.00003 8.87722C8.00003 9.56115 8.40873 10.3914 9.21878 11.3564C9.02055 11.911 8.78762 12.5506 8.52592 13.2717C8.39992 13.6177 8.26323 13.9381 8.11867 14.2344C8.00102 14.2823 7.88673 14.3311 7.77639 14.3817C7.37894 14.5639 7.0015 14.7277 6.65139 14.8799C5.05469 15.5727 4 16.0311 4 16.9361C4 17.5932 4.77883 18 5.5 18C6.42967 18 7.83348 16.8618 8.85888 14.9443C9.92331 14.5594 11.2466 14.2742 12.291 14.0956C13.1279 14.6856 14.0522 15.25 14.5 15.25C15.7398 15.25 16 14.5929 16 14.0419C16 12.9583 14.6494 12.9583 14 12.9583C13.7983 12.9583 13.2573 13.013 12.5493 13.1217ZM5.5 17.0833C5.21434 17.0833 5.02098 16.9598 4.99998 16.9361C4.99998 16.6111 6.05711 16.1519 7.07959 15.7079C7.14452 15.6797 7.21047 15.6515 7.27736 15.6224C6.52638 16.6206 5.78369 17.0833 5.5 17.0833ZM9.00002 8.87722C9.00002 7.9167 9.32524 7.9167 9.50003 7.9167C9.85356 7.9167 10 7.91669 10 8.83335C10 9.02671 9.85942 9.51011 9.60208 10.2648C9.20945 9.71064 9.00002 9.23575 9.00002 8.87722ZM9.38331 13.8034C9.41458 13.7238 9.44486 13.6432 9.47416 13.5617C9.65969 13.0515 9.8267 12.5931 9.97563 12.1804C10.1831 12.3899 10.4068 12.6043 10.6465 12.8241C10.7403 12.91 10.9727 13.1034 11.2823 13.3455C10.666 13.4686 10.0103 13.6212 9.38331 13.8034ZM15 14.042C15 14.2479 15 14.3333 14.5361 14.3361C14.3999 14.3092 14.085 14.1391 13.6963 13.8965C13.8374 13.8822 13.9414 13.875 14 13.875C14.7388 13.875 14.9483 13.9413 15 14.042Z"
          fill={color}
          className="fill-path"
        />
        <path
          d="M14 1V4C14 5.10457 14.8954 6 16 6H19"
          stroke={color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </span>
  );
};
PdfIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default PdfIcon;
