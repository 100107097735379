import { colors } from "@/lib/styles";
import React, { useCallback, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import styled from "styled-components";
import CaretIcon from "../icons/CaretIcon";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
`;

const BackButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    background-color: ${colors.nearWhite};
  }
`;

const NextButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    background-color: ${colors.nearWhite};
  }
`;

type Props = {
  url: string;
  width?: number;
  pageNumber?: number;
  done?: () => void;
};
const PdfViewer: React.FC<Props> = ({ url, width, pageNumber, done }) => {
  const [page, setPage] = useState(pageNumber || 1);
  const [pageCount, setPageCount] = useState(null);

  useEffect(() => {
    if (pageCount && page == pageCount && done) done();
  }, [page, pageCount]);

  const lastPage = useCallback(() => {
    setPage((p) => Math.max(p - 1, 1));
  }, [pageCount]);

  const nextPage = useCallback(() => {
    setPage((p) => (p + 1) % (pageCount + 1));
  }, [pageCount]);

  const load = (pdf) => {
    console.log(pdf);
    setPageCount(pdf?._pdfInfo?.numPages);
    console.log(pdf?._pdfInfo?.numPages);
  };

  return (
    <Wrapper>
      <Document
        key="pdf-document"
        onLoadSuccess={load}
        onLoadError={console.error}
        file={url}
      >
        <Page pageNumber={page} width={width} />
        {page > 1 && (
          <BackButton onClick={lastPage}>
            <CaretIcon
              color={colors.nearBlack}
              style={{ transform: "rotate(180deg)" }}
              size={24}
            />
          </BackButton>
        )}
        {pageCount && pageCount > 1 && page !== pageCount && (
          <NextButton onClick={nextPage}>
            <CaretIcon color={colors.nearBlack} size={24} />
          </NextButton>
        )}
      </Document>
    </Wrapper>
  );
};

export default PdfViewer;
