import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const VideoIcon: React.FC<Props> = (props) => {
  const width = props.size ? String(props.size) : "21";
  const height = props.size ? String((props.size * 15) / 21) : "15";

  return (
    <span style={props.style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.75 2.29171C16.75 1.14275 15.8156 0.208374 14.6666 0.208374H2.16665C1.01769 0.208374 0.083313 1.14275 0.083313 2.29171V12.7084C0.083313 13.8573 1.01769 14.7917 2.16665 14.7917H14.6666C15.8156 14.7917 16.75 13.8573 16.75 12.7084V9.2365L20.9166 12.7084V2.29171L16.75 5.76358V2.29171ZM14.6687 12.7084H2.16665V2.29171H14.6666L14.6677 7.499L14.6666 7.50004L14.6677 7.50108L14.6687 12.7084Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};
VideoIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default VideoIcon;
