import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const DescriptionIcon: React.FC<Props> = (props) => {
  const width = String(props.size);
  const height = String((10 / 14) * props.size);

  return (
    <span style={props.style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75H13C13.4142 1.75 13.75 1.41421 13.75 1C13.75 0.585786 13.4142 0.25 13 0.25H1ZM1 2.9165C0.585786 2.9165 0.25 3.25229 0.25 3.6665C0.25 4.08072 0.585786 4.4165 1 4.4165H10.3333C10.7475 4.4165 11.0833 4.08072 11.0833 3.6665C11.0833 3.25229 10.7475 2.9165 10.3333 2.9165H1ZM0.25 6.3335C0.25 5.91928 0.585786 5.5835 1 5.5835H13C13.4142 5.5835 13.75 5.91928 13.75 6.3335C13.75 6.74771 13.4142 7.0835 13 7.0835H1C0.585786 7.0835 0.25 6.74771 0.25 6.3335ZM1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H10.3333C10.7475 9.75 11.0833 9.41421 11.0833 9C11.0833 8.58579 10.7475 8.25 10.3333 8.25H1Z"
          fill={props.color}
        />
      </svg>
    </span>
  );
};
DescriptionIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default DescriptionIcon;
