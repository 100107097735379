import React from "react";
import styled from "styled-components";
import { WrappedFieldProps } from "redux-form";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import CheckIcon from "@/components/icons/CheckIcon";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  position: relative;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  margin-right: 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
  margin-top: 5px;
`;

const Icon = styled.div<{ padded: boolean }>`
  left: 15px;
  top: ${(props) => (props.padded ? 38 : 21)}px;
  height: 16px;
  width: 16px;
  object-fit: contain;
  position: absolute;
`;
const StyledInput = styled.input<{ error: string; padded: boolean }>`
  border-radius: 10px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: ${colors.nearBlack};
  outline: none;
  padding: 10px 15px 10px ${(props) => (props.padded ? 40 : 15)}px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid ${(props) => (props.error ? colors.red : colors.lightGray)};
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:focus,
  &:hover {
    border: 1px solid ${(props) => (props.error ? colors.red : colors.gray)};
  }
`;

type OwnProps = {
  type: "text" | "number" | "password" | "email";
  title?: string;
  style?: Record<string, unknown>;
  inputStyle?: Record<string, unknown>;
  labelStyle?: Record<string, unknown>;
  quiz?: boolean;
};

type Props = {
  input: Partial<WrappedFieldProps["input"]>;
  meta?: Partial<WrappedFieldProps["meta"]>;
  icon?: React.ReactElement;
} & OwnProps &
  Partial<React.HTMLProps<HTMLInputElement>>;

const Input: React.FC<Props> = (props) => {
  const {
    input,
    type,
    title,
    meta,
    style,
    icon,
    inputStyle,
    labelStyle,
    quiz,
    ...otherProps
  } = props;

  const owner = useOwner();
  if (!input) return null;

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = type === "number" ? Number(e.target.value) : e.target.value;
    if (input.onChange) input.onChange(newVal);
  };

  return (
    <Wrapper style={style}>
      {title && (
        <Top>
          <Label style={labelStyle}>{title}</Label>
          {quiz && meta.submitFailed && (
            <CheckIcon height={20} color={ownerColor(owner)} />
          )}
        </Top>
      )}
      {!!icon && <Icon padded={Boolean(title)}>{icon}</Icon>}
      <StyledInput
        value={String(input.value)}
        onChange={_onChange}
        type={type}
        padded={Boolean(icon)}
        style={inputStyle}
        error={meta?.error}
        data-cy={`input-${input.name}`}
        {...(otherProps as any)}
      />
      {meta?.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};

export default Input;
