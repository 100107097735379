import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  color?: string;
  style?: Record<string, unknown>;
};
const ImageIcon: React.FC<Props> = (props) => {
  const size = String(props.size) || "22";

  return (
    <span style={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={props.color || colors.darkGray}
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="2"
          ry="2"
          vectorEffect="non-scaling-stroke"
        />
        <circle cx="8.5" cy="8.5" r="1.5" vectorEffect="non-scaling-stroke" />
        <polyline points="21 15 16 10 5 21" vectorEffect="non-scaling-stroke" />
      </svg>
    </span>
  );
};

export default ImageIcon;
